.container {
  display: flex;
  flex-direction: row;
  background-color: "#f0f2f5";
}

.contentContainer {
  flex: 1;
  padding: 20px;
}

.divider {
  border-bottom: 2px dashed black;
  border-top: 1px dashed #eeeeee;
  margin: 20px;
}

.filterRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .filterRow {
    flex-direction: column;
  }
}

.filterContainer {
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.filterLabel {
  margin-bottom: 5px;
  font-weight: bold;
}

.filterInput {
  height: 20px;
  width: 200px;
  border: 1px solid "#272727";
  border-radius: 4px;
  padding: 5px;
}

.addButton {
  margin-left: auto;
  background-color: "#8dc63f";
  padding: 10px 20px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border-color: #8dc63f;
  border: solid;
}

.buttonstyleR33 {
  background-color: #ffffff;
  border-radius: 10px;
  color: #8dc63f;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 10px;
  border-color: #8dc63f;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-weight: 500;
  max-width: 33%;
  border: solid;
}
@media (max-width: 428px) {
  .addButton {
    margin-left: 0;
    margin-right: auto;
  }
}

.contractCard {
  flex: 1;
  background-color: "#FFFFFF";
  border-radius: 4px;
  padding: 10px;
  margin: 10px;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  elevation: 4;
}

.contractname {
  font-weight: bold;
  color: "#2E2E2E";
}

.email {
  margin-bottom: 10px;
  color: "#2E2E2E";
}

.contractActions {
  display: flex;
  flex-direction: row;
}

.confirmationModal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.confirmationText {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  color: "#FFFFFFF";
}

.confirmationButtons {
  display: flex;
  flex-direction: row;
}

.confirmButton {
  background-color: "#235266";
  padding: 10px 20px;
  border-radius: 4px;
  margin-right: 10px;
}

.cancelButton {
  background-color: "#272727";
  padding: 10px 20px;
  border-radius: 4px;
}

.confirmButtonText {
  color: "#FFFFFF";
  font-weight: bold;
}

.cancelButtonText {
  color: "#FFFFFF";
  font-weight: bold;
}
.actionIconEdit {
  color: #8dc63f;
}

.actionIconDelete {
  color: red;
}
