/* .popupContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
  }
  
  .popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  .popupContent {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1000;

  }
  
  .popupContent button {
    margin-top: 10px;
  }
   */

.popupContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.popupContent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%; /* Set a maximum width to allow for responsiveness */
  max-height: 90%;
  width: auto; /* Let the content determine the width */
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex; /* Use Flexbox */
  flex-direction: column; /* Arrange children in a column */
}

.popupContent button {
  margin-top: 10px;
}

.popupContent .MuiTableContainer-root {
  flex: 1; /* Allow the table container to expand */
  overflow-x: auto; /* Allow horizontal scrolling */
}

.popupContent .MuiTableCell-head,
.popupContent .MuiTableCell-body {
  white-space: nowrap; /* Prevent text from wrapping */
  /* min-width: 100px; Set a minimum width for cells */
}

.popupContent .MuiTableCell-head:nth-child(1),
.popupContent .MuiTableCell-body:nth-child(1) {
  min-width: 150px; /* Set a wider minimum width for the first column */
}

.popupContent .MuiTable-root {
  border-collapse: separate;
  border-spacing: 0 2px; /* Add spacing between rows */
}

.popupContent .MuiTableRow-root {
  border-top: 1px solid #e0e0e0; /* Add top border for each row */
  border-bottom: none; /* Remove bottom border for all rows */
}

.popupContent .MuiTableRow-root:last-child {
  border-bottom: 1px solid #e0e0e0; /* Add bottom border for the last row */
}

.popupContent .MuiTableContainer-root .MuiPaper-root {
  border: none; /* Remove the border from MuiPaper */
}

.popupContent .MuiTable-root {
  border-collapse: separate;
  border-spacing: 0 2px; /* Add spacing between rows */
}

.popupContent .MuiTableRow-root {
  border-top: 1px solid #e0e0e0; /* Add top border for each row */
  border-bottom: none; /* Remove bottom border for all rows */
}

.popupContent .MuiTableRow-root:last-child {
  border-bottom: 1px solid #e0e0e0; /* Add bottom border for the last row */
}

@media (max-width: 600px) {
  .popupContent {
    width: 90%; /* Adjust the width for small screens */
  }
}
