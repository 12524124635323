.pageContainer {
  display: flex;
  min-height: 100vh;
  background-color: "#f8f9fa";
}

.contentContainer {
  flex: 1;
  background-color: #f9f8fa;
  /* boxShadow: none; */
  box-shadow: none !important;
  padding: 20px;
  transition: all 0.3s;
}

.pageTitle {
  font-size: 24px;
  font-weight: bold;
}

.uploadButton {
  background-color: #8dc63f;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 10px;
}

.confirmationModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmationContainer {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10;
}

.dropzone {
  height: 300px;
  margin-top: 30px;
  width: 90%;
  border: 3px dashed grey;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropzone p {
  text-align: center;
  font-size: 18px;
  color: #555555;
}

.active {
  background-color: #f8f9fa;
}

.documentGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 30px;
}

.documentCard {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.documentName {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  text-decoration: none;
}

.documentActions {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.deleteButton,
.downloadButton {
  background-color: #8dc63f;
  color: #ffffff;
  border: none;
  padding: 8px 16px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.deleteButton:hover,
.downloadButton:hover {
  background-color: #8dc63f;
}
