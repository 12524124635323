/* .container {
    display: flex;
    flex: 1;
  }
  
  .pageContainer {
    display: flex;
    flex: 1;
  }

  .sidebarContainer {
    flex: 1;
    width: 20%;
    background-color: #f0f0f0;
  }
  
  .contentContainer {
    flex: 4;
    background-color: #fff;
    padding: 20px;
  }
  
  .formContainer {
    margin-top: 20px;
  }
  
  .label {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .input {
    height: 20px;
    border-color: #ccc;
    border-width: 1px;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 10px;
  }
  
  .dropdown {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }
  
  .option {
    margin-right: 10px;
    padding: 10px 15px;
    border-radius: 5px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
  }
  
  .selectedOption {
    background-color: #ccc;
  }
  
  .updateButton {
    margin-top: 20px;
    background-color: #2196f3;
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    border: none;
    cursor: pointer;
  }
  
  .errorMessage {
    color: red;
    margin-bottom: 10px;
  }
  
  .dividerDashed {
    border-bottom: 2px dashed black;
    border-top: 1px dashed #eeeeee;
    margin: 20px;
  }
  
  .label {
    display: block;
  }

  .form-group {
    margin-bottom: 10px;
  }

  .userCardContact {
    background-color: white;
    border-radius: 4px;
    padding: 20px;
    margin: 10px;
    align-items: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    elevation: 4;
  }

  .green-divider {
    width: 80px;
    height: 4px;
    background-color: #8dc63f;
    margin-bottom: 10px;
  }
   .userCardContainerMyProfile {
    display: grid;
   grid-template-columns: 1fr 1fr; 
    gap: 20px;
  } 
  
@media (max-width: 400px) {
  .userCardContainerMyProfile {
    flex-direction: column; 
    align-items: stretch; 
    display: block;
  }

  .userCardColumn {
    margin-bottom: 20px; 
  }
} */

.container {
  display: flex;
  flex: 1;
}

.pageContainer {
  display: flex;
  flex: 1;
}

.sidebarContainer {
  flex: 1;
  width: 20%;
  background-color: #f0f0f0;
}

.contentContainer {
  flex: 4;
  background-color: #fff;
  padding: 20px;
}

.formContainer {
  margin-top: 20px;
}

.label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.input {
  height: 20px;
  border-color: #ccc;
  border-width: 1px;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 10px;
}

.dropdown {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.option {
  margin-right: 10px;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
}

.selectedOption {
  background-color: #ccc;
}

.updateButton {
  margin-left: auto;
  padding: 10px 20px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: none;
  background-color: "#8dc63f";
}

.errorMessage {
  color: red;
  margin-bottom: 10px;
}

.dividerDashed {
  border-bottom: 2px dashed black;
  border-top: 1px dashed #eeeeee;
  margin: 20px;
}

.label {
  display: block;
}

.form-group {
  margin-bottom: 10px;
}

.flex-align-bottom {
  display: flex;
  align-items: end;
}

.userCardContact {
  background-color: white;
  border-radius: 4px;
  padding: 20px;
  margin: 10px;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  elevation: 4;
}

.green-divider {
  width: 80px;
  height: 4px;
  background-color: #8dc63f;
  margin-bottom: 10px;
}

.userCardContainerMyProfile {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.userCardColumnMyProfile {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

@media (max-width: 450px) {
  .userCardContainerMyProfile {
    display: block;
  }

  .userCardColumnMyProfile {
    margin-bottom: 20px;
  }
}
