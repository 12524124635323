/* Footer styles */
.footer {
  display: flex;
  justify-content: center;
  background-color: #16222f;
  padding: 20px;
  color: white;
  border-top-left-radius: 20px; /* Round the top left corner */
  border-top-right-radius: 20px; /* Round the top right corner */
}

.logo-info,
.menu-section {
  width: 25%;
  padding: 10px;
  box-sizing: border-box;
}

/* Logo and information section */
.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 50px;
  cursor: pointer;
}

/* Menu sections */
.menu-section h3 {
  margin-bottom: 10px;
}

.menu-section ul {
  list-style: none;
  padding: 0;
}

.menu-section li {
  margin-bottom: 5px;
}

/* Menu item styles */
.menu-section a {
  color: white; /* Set text color to white */
  text-decoration: none; /* Remove underlines */
}
.phone {
  color: white;
}

/* Responsive styles */
@media (max-width: 768px) {
  .footer {
    flex-direction: column; /* Stack items under each other */
    align-items: center; /* Center items horizontally */
  }

  .logo-info,
  .menu-section {
    width: 100%; /* Take up full width */
    text-align: center; /* Center text */
    padding: 10px;
  }

  .menu-section {
    margin-top: 20px; /* Add spacing between sections */
  }
}
