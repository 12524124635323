.pageContainerE {
    display: flex;
    min-height: 100vh;
    background-color: #f8f9fa;
}

.contentContainer {
    flex: 1;
    background-color: #f8f9fa;
    box-shadow: none;
    padding: 20px;
    transition: all 0.3s;
}

.squareContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;
}

.square {
    width: 200px;
    height: 200px;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 50px;
    margin: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.squarePreferred {
    border: 5px solid;
    border-image: linear-gradient(45deg, #269b66, #ffd700) 1;
    position: relative;
}

.preferredText {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(45deg, #269b66, #FFD700);
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: transparent;
    cursor: pointer;
    border: 1px solid rgb(211, 230, 243);
    width: 100%;
    border-radius: 4px;
    padding: 0.6rem 1.5rem;
    font-size: 12px;
    font-weight: 600;
    color: rgb(18, 31, 67);
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1),
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1),
    color 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

