.navigation {
  flex: 0 0 10%;
  width: 100%;
  background-color: #16222f;
  padding: 20px;
  transition: all 0.3s;
  overflow: hidden;
  /* border-radius: 20px; */
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.menuItem {
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: block;
  color: white;
}

.menuItem:hover {
  background-color: #000000;
}

.menuItem:not(:hover) {
  background-color: transparent;
}

.menuItem span {
  margin-right: 5px;
}
