.faq-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background-color: #f8f9fa;
    padding: 20px;
  }
  
  .faq-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 100%;
    max-width: 800px;
  }
  
  .faq-item {
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .faq-item:hover {
    background-color: #f0f0f0;
  }
  
  .faq-question {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  
  .faq-answer {
    font-size: 14px;
    color: #555;
    padding-top: 10px;
    transition: max-height 0.3s ease-in-out;
  }
  
  
  @media (max-width: 768px) {
    .faq-grid {
      grid-template-columns: 1fr;
    }
  }
  