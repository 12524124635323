/* Reset margins and padding
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
} */

/* Full-page background color */
.full-page-background {
  background-color: #f8f9fa; /* The same background color for the entire page */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Centered page container */
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
}

/* Content container with limited width for readability */
.content-container {
  max-width: 800px;
  width: 100%;
  padding: 20px;
  background-color: #f8f9fa;
}

/* Featured image styling */
.featured-image {
  text-align: center;
  margin-bottom: 20px;
}

.featured-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Blog content styling */
.blog-content {
  line-height: 1.6;
  color: #333;
}

.blog-content p {
  margin-bottom: 20px;
  font-size: 18px;
}

.blog-content h2 {
  font-size: 1.5rem;
  margin-top: 30px;
  margin-bottom: 15px;
}
.section-containera {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* gap: 20px; */
  /* margin-bottom: 40px; */
}

.section-container-full {
  /* display: grid;
    grid-template-columns: 1fr 1fr; */
  gap: 20px;
  margin-bottom: 40px;
  padding-left: 5%;
  padding-right: 5%;
}

.iconlist-spacing {
  margin-bottom: 20px;
}

.iconlist-spacing a {
  color: black; /* Set link color to black */
  text-decoration: none; /* Remove underline from links */
  display: inline-flex; /* Display links and icons in a row */
  align-items: center; /* Align items vertically */
}

.iconlist-spacing a:hover {
  color: #8dc63f; /* Change link color on hover */
}

.iconlist-spacing a span {
  color: #8dc63f; /* Set icon color to green */
  font-size: 20px; /* Adjust icon size */
  display: inline-block; /* Ensure icon stays inline with text */
  margin-right: 5px; /* Add space between icon and text */
}

.text-columna {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 20px; */
  background-color: #f9f8fa;
  padding-left: 10%;
  padding-right: 15%;
}

.green-divider {
  width: 80px;
  height: 4px;
  background-color: #8dc63f;
  margin-bottom: 10px;
}

.section-title {
  font-size: 32px;
  margin-bottom: 10px;
}

.section-paragraph {
  font-size: 18px;
}

.section-paragraph-full {
  font-size: 18px;
  /* padding-left: 5%;
    padding-right: 5%; */
}

.image-column {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10%;
  padding-right: 10%;
  /* padding-top: 5%; */
}

.section-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
}


/* Responsive adjustments */
@media (max-width: 768px) {
  .content-container {
    padding: 15px;
  }

  .blog-content h2 {
    font-size: 1.25rem;
  }
  .section-container {
    grid-template-columns: 1fr; /* Display as a single column */
  }

  .text-column,
  .image-column {
    padding: 5% 10%; /* Adjust padding for better spacing on smaller devices */
    text-align: left; /* Center-align text content on smaller devices */
  }

  .green-divider {
    width: 80px;
    margin: 0 auto; /* Center-align the green divider */
  }

  .image-column {
    order: 2; /* Swap order for larger screens */
  }

  .text-column {
    order: 1; /* Swap order for larger screens */
  }

}
