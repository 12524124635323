.partnerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
}

.partnerLogoName {
  display: flex;
  height: 30vh;
  width: 80%;
  margin-bottom: 20px;
}

.partnerLogoBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.partnerLogo {
  /* height: 100%;
    max-height: 100%; */
  max-width: 300px;
}

.partnerNameBox {
  display: flex;
  align-items: center;
  width: 50%;
}

.partnerName {
  padding: 20px;
  font-size: 32px;
}

.divider-partners {
  height: 1px;
  width: 80%;
  background: #4caf50;
  margin-bottom: 20px;
}
