.buttonstyle {
  background-color: #8dc63f;
  border-radius: 10px;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-weight: 500;
}
.buttonstyleMiddle {
  background-color: #8dc63f;
  border-radius: 10px;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-weight: 600;
  font-size: 24px;
}

.parent-container {
  display: flex;
  justify-content: center;
  /* align-items: center; If you want to center vertically as well */
  /* height: 100%; Ensure the parent has a height for vertical centering */
}

.buttonstylePopUp {
  background-color: #8dc63f;
  border-radius: 10px;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  /* border: none; */
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
  font-weight: 500;
  border: solid;
  border-width: 2px;
  border-color: white;
}
.buttonstyleR {
  background-color: #ffffff;
  border-radius: 10px;
  color: #8dc63f;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-color: #8dc63f;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-weight: 500;
  border: solid;
}

.buttonstyle33 {
  background-color: #8dc63f;
  border-radius: 10px;
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-weight: 500;
  max-width: 33%;
  margin-top: 10px;
}

.buttonstyleR33 {
  background-color: #ffffff;
  border-radius: 10px;
  color: #8dc63f;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-color: #8dc63f;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-weight: 500;
  max-width: 33%;
  border: solid;
}

@media screen and (max-width: 500px) {
  .buttonstyle33 {
    background-color: #8dc63f;
    border-radius: 10px;
    color: #ffffff;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    font-weight: 500;
    max-width: 100%;
    margin-top:10px;
  }
  .buttonstyleR33 {
    background-color: #ffffff;
    border-radius: 10px;
    color: #8dc63f;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-color: #8dc63f;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    font-weight: 500;
    max-width: 100%;
    border: solid;
  }
}
