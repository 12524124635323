.listleft {
  align-items: start;
  padding-left: 30px;
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 50px;
}

.titleContract {
  font-size: 24px;
}

.greenbg {
  color: #3d3e3d;
  background-color: #d5e6ce;
  border-radius: 10px;
}

.paddingClassGreen {
  padding-left: 30px;
  padding-top: 20px;
  padding-right: 30px;
  padding-bottom: 20px;
}

.paddingClass {
  padding-left: 30px;
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 100px;
}

.css-13vtz3x-MuiPaper-root-MuiTableContainer-root {
  background-color: #fff;
  color: #fff;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px white !important;
  width: 100%;
  overflow-x: auto;
  border: none;
}
