.SectionHeroColored {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: #8dc63f; */
  background: linear-gradient(45deg, #8dc63f, #269b66, #ffd700);
  /* background-image: url('../../assets/background.jpg'); */
  background-size: 300% 300%;
  animation: color 12s ease-in-out infinite;
  background-position: center;
  height: 200px;
  position: relative;
  color: white;
  text-align: center;
  overflow: hidden;
  margin-bottom: 40px;
  padding-bottom: 30px;

  /* Add rounded corners at the top */
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

@keyframes color {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.SubText {
  font-size: 18px; /* Adjust the font size as needed */
  margin: 0; /* Remove default margin */
}

.SectionHeroColored::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: -20px; /* Adjust this value to control the height of the straight bottom */
  /* background-color: white; Set the color you want for the straight bottom */
  background-color: #8dc63f; /* Use the same green color here */
}
