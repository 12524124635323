.squareNoti {
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  position: relative;
  padding: 20px;
  margin-top: 15px;
}
.greenLine {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #8dc63f;
  border-radius: 0 0 10px 10px;
}

@media (max-width: 812px) {
  .squareNoti {
    margin-top: 5px;
  }
}

@media (max-width: 500px) {
  .squareNoti {
    margin-top: -35px;
    margin-left: 20px;
    margin-right: 20px;
  }
}
