.section-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 40px;
}

.iconlist-spacing {
  margin-bottom: 20px;
}

.iconlist-spacing a {
  color: black; /* Set link color to black */
  text-decoration: none; /* Remove underline from links */
  display: inline-flex; /* Display links and icons in a row */
  align-items: center; /* Align items vertically */
}

.iconlist-spacing a:hover {
  color: #8dc63f; /* Change link color on hover */
}

.iconlist-spacing a span {
  color: #8dc63f; /* Set icon color to green */
  font-size: 20px; /* Adjust icon size */
  display: inline-block; /* Ensure icon stays inline with text */
  margin-right: 5px; /* Add space between icon and text */
}

.text-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background-color: #f9f8fa;
  padding-left: 10%;
  padding-right: 15%;
}

.text-columntwo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background-color: #f9f8fa;
  padding-left: 10%;
  padding-right: 15%;
}

.text-columnthree {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background-color: #f9f8fa;
  padding-left: 10%;
  padding-right: 15%;
}

.green-divider {
  width: 80px;
  height: 4px;
  background-color: #8dc63f;
  margin-bottom: 10px;
}

.section-title {
  font-size: 32px;
  margin-bottom: 10px;
}

.section-paragraph {
  font-size: 18px;
}

.image-column {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
}

.image-columntwo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
}

.image-columnthree {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
}

.section-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
}

/* Media query for smaller devices */
@media (max-width: 768px) {
  .section-container {
    grid-template-columns: 1fr; /* Display as a single column */
  }

  .text-column,
  .image-column,
  .text-columntwo,
  .image-columntwo,
  .text-columnthree,
  .image-columnthree {
    padding: 5% 10%; /* Adjust padding for better spacing on smaller devices */
    text-align: center; /* Center-align text content on smaller devices */
  }

  .green-divider {
    width: 80px;
    margin: 0 auto; /* Center-align the green divider */
  }

  .image-column {
    order: 2; /* Swap order for larger screens */
  }

  .text-column {
    order: 1; /* Swap order for larger screens */
  }

  .image-columntwo {
    order: 4; /* Swap order for larger screens */
  }

  .text-columntwo {
    order: 3; /* Swap order for larger screens */
  }

  .image-columnthree {
    order: 6; /* Swap order for larger screens */
  }

  .text-columnthree {
    order: 5; /* Swap order for larger screens */
  }
}
