p {
  text-align: left; /* Ensure all <p> elements are aligned left */
  margin: 0; /* Optional: Adjust margins if necessary */
}

.pageContainerE {
  display: flex;
  flex: 1 1;
}
.containersolar {
  display: flex;
  flex: 1;
}

.parentContainer {
  display: flex;
}

.sidebarContainersolar {
  flex: 1;
  width: 20%;
  background-color: #f9f8fa;
}
.parentContainer {
  width: 100%;
}

.contentContainersolar {
  display: flex;
  background-color: #f9f8fa;
  padding: 20px;
  box-shadow: none;
  width: 80%;
  height: fit-content;
  transition: all 0.3s;
}

.userCardRequestsolar {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  /* margin: 10px; */
  /* align-items: center; */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  elevation: 4;
  width: 100%;
}

.userCardRequestColumnssolar {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
}

.MuiBox-root > div:nth-of-type(2) {
  margin: 20px 0;
}

.MuiBox-root > div:nth-of-type(2) > div > div > div > label:nth-of-type(1) {
  transform: none;
}

.userCardColumnsolar {
  width: 100%;
  padding: 20px;
}

.textColumnsolar {
  width: 20%;
  background-color: #f9f8fa;
  padding: 20px;
}

.formContainersolar {
  margin-top: 20px;
}

.labelsolar {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.inputsolar {
  height: 20px;
  border-color: #ccc;
  border-width: 1px;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 10px;
}

.dropdownsolar {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.optionsolar {
  margin-right: 10px;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
}

.selectedOptionsolar {
  background-color: #ccc;
}

.updateButtonsolar {
  margin-top: 20px;
  background-color: #2196f3;
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.errorMessagesolar {
  color: red;
  margin-bottom: 10px;
}

.dividersolar {
  border-bottom: 1px solid black;
  margin: 20px;
}

.labelsolar {
  display: block;
}

.addressFieldsContainer {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

.userCardColumnsolar {
  flex: 1;
  width: 50%;
}

.addressFieldsColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.addressField {
  width: 100%;
}

@media (max-width: 812px) {
  .contentContainersolar {
    display: flex;
    background-color: #f9f8fa;
    padding: 20px;
    box-shadow: none;
    width: 80%;
    height: fit-content;
    transition: all 0.3s;
  }
}

@media (max-width: 500px) {
  .css-m5vj9m-MuiStepper-root {
    display: none;
  }

  .userCardRequestColumnssolar {
    flex-direction: column;
    gap: 0;
  }

  .userCardColumnsolar {
    width: 100%;
    padding: 10px;
  }

  .addressFieldsColumn {
    gap: 10px;
  }

  .contentContainersolar {
    display: flex;
    background-color: #f9f8fa;
    padding: 20px;
    box-shadow: none;
    width: 80%;
    height: fit-content;
    transition: all 0.3s;
  }

  .parentContainer {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content horizontally */
  }

  .userCardRequestsolar {
    width: 100%; /* Full width on smaller screens */
  }

  .textColumnsolar {
    width: 100%; /* Full width on smaller screens */
    margin-top: 20px; /* Add some space between the sections */
  }
}
