.container {
  flex: 1;
}

.contentContainer {
  flex: 1;
  padding: 20px;
}

.label {
  font-weight: bold;
  margin-bottom: 5px;
}

.text {
  margin-bottom: 20px;
}

.editButtonTop {
  margin-left: auto;
  background-color: green;
  padding: 10px 20px;
  border-radius: 4px;
  color: white;
  font-weight: bold;
}

.dividerci {
  border-bottom-width: 1px;
  border-bottom-color: black;
  margin: 20px;
}

.userCard {
  flex: 1;
  background-color: white;
  border-radius: 4px;
  padding: 20px;
  margin: 10px;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  elevation: 4;
}

.username {
  font-weight: bold;
  color: black;
}

.email {
  margin-bottom: 10px;
  color: black;
}

.energysuppliername {
  margin-bottom: 10px;
  color: black;
}

.userName {
  font-weight: bold;
  color: black;
  margin-bottom: 10px;
}

.userDescription {
  margin-bottom: 10px;
  color: black;
}

.userCategory {
  color: black;
}

.userActions {
  flex-direction: row;
}

.editButtonLabel {
  color: white;
  font-weight: bold;
}
