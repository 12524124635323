/* css */

/* .section-container1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 40px;
} */

.section-container1 {
  display: grid;
  grid-template-columns: 3fr 2fr; /* Adjust the column widths as needed */
  gap: 20px;
  margin-bottom: 40px;
}

/*.text-column1 {
  display: flex;
  flex-direction: column; 
  justify-content: center; */
/* padding: 20px; */
/* background-color: #f9f8fa;
  padding-left: 10%; */
/* padding-right: 15%; */
/* 
}
*/

.text-column1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background-color: #f9f8fa;
}

.squareContainerStyles1 {
  display: flex;
  justify-content: left;
  align-items: left;
  margin-top: 20px;
  flex-wrap: wrap;
  width: 100%;
}

.text-columntwo1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background-color: #f9f8fa;
  padding-left: 10%;
  padding-right: 15%;
}

.text-columnthree1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background-color: #f9f8fa;
  padding-left: 10%;
  padding-right: 15%;
}

.green-divider1 {
  width: 80px;
  height: 4px;
  background-color: #8dc63f;
  margin-bottom: 10px;
}

.section-title1 {
  font-size: 40px;
  margin-bottom: 10px;
}

/* .section-paragraph1 {
  font-size: 18px;
} */
.section-paragraph1 {
  font-size: 18px;
  text-align: left; 
  margin-bottom: 16px; 
}

/* .image-column-energycomparison1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%; 
 
} */

.image-column-energycomparison1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  background-color: #f9f8fa;
}

.image-columntwo1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
}

.image-columnthree1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
}

.section-image1 {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
}

.icon-text-container1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.square1 {
  width: 150px;
  height: 100px;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  padding-top: 30px;
  margin: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 480px) {
  .squareContainer1 {
    flex-direction: column; /* Display cards in a column on smaller screens */
  }

  .section-title1 {
    font-size: 28px;
    margin-bottom: 10px;
  }

  .squareContainerStyles1 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;
    width: 100%;
  }
}

@media (max-width: 768px) {
  /* iPad screens */
  .squareContainer1 {
    /* justify-content: flex-start;  */

    grid-template-columns: 1fr; /* Display as a single column on smaller screens */
  }
  .section-container1 {
    grid-template-columns: 1fr; /* Display as a single column */
  }

  .text-column1,
  .image-column1 {
    padding: 5% 10%; /* Adjust padding for better spacing on smaller devices */
    text-align: center; /* Center-align text content on smaller devices */
  }

  .green-divider1 {
    width: 80px;
    margin: 0 auto; /* Center-align the green divider */
  }
  .squareContainerStyles1 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;
    width: 100%;
  }
}
