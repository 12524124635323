.address-card {
  padding: 10px 20px;
  margin: 20px 0;
}

.card-header {
  display: flex;
  justify-content: space-between;
}

.buttonstyleR33Q {
  background-color: #ffffff;
  border-radius: 10px;
  color: #8dc63f;
  padding: 10px 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-weight: 500;
  border: solid #8dc63f;
  margin-left: 20px;
}

.edit-buttons {
  /* display: none; */
}

.address-card:hover .edit-buttons {
  display: block;
}
