.heroColored {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: linear-gradient(45deg, #8dc63f, #269b66, #39a0ed, #FFD700); */
  background: linear-gradient(45deg, #8dc63f, #269b66, #ffd700);
  /* background-image: url('../../assets/background.jpg'); */
  background-size: 300% 300%;
  animation: color 12s ease-in-out infinite;
  background-position: center;
  height: 300px;
  position: relative;
  color: white;
  text-align: center;
  overflow: hidden;
  /* Add rounded corners at the top */
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.heroColored::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: -20px; /* Adjust this value to control the height of the straight bottom */
  background-color: white; /* Set the color you want for the straight bottom */
}
