.userCard {
  flex: 1;
  background-color: white;
  border-radius: 4px;
  padding: 20px;
  margin: 10px;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  elevation: 4;
}
.contentContainer {
  flex: 1;
  padding: 20px;
}
