body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.login-page {
  display: flex;
  height: 100vh;
}

.login-background {
  flex: 7;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(45deg, #8dc63f, #269b66, #ffd700);
  background-size: 300% 300%;
  animation: color 12s ease-in-out infinite;
}

.login-logo {
  width: 150px;
  height: auto;
}

.login-form {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100%;
}

.login-button {
  width: 150px;
  margin: 10px auto;
  padding: 10px;
  border-radius: 10px;
  background-color: #8dc63f;
  color: white;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.custom-link1,
.custom-link2 {
  color: grey;
  text-decoration: none;
  font-size: smaller;
  padding: 10px;
}

@media (max-width: 600px) {
  .login-page {
    flex-direction: column-reverse;
  }

  .login-form {
    flex: 7;
    width: 100%;
    align-items: center;
  }
}

@keyframes color {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}



/* body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.login-page {
  display: flex;
  height: 100vh;
}

.login-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.login-image {
  flex: 1;
  background-image: url("../../../../assets/background.jpg");
  background-size: cover;
}

@media (max-width: 600px) {
  .login-page {
    flex-direction: column-reverse;
  }

  .login-form {
    width: 100%;
    align-items: center;
  }

  .login-image {
    height: 70%;
  }
} */
