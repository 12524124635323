.container {
  flex: 1;
}

.contentContainer {
  flex: 1;
  padding: 20px;
}

.label {
  font-weight: bold;
  margin-bottom: 5px;
}

.text {
  margin-bottom: 20px;
}

.editButtonTop {
  margin-left: auto;
  background-color: green;
  padding: 10px 20px;
  border-radius: 4px;
  color: white;
  font-weight: bold;
}

.dividerDashed {
  border-bottom: 2px dashed black;
  border-top: 1px dashed #eeeeee;
  margin: 20px;
}

.userCard {
  flex: 1;
  background-color: white;
  border-radius: 4px;
  padding: 20px;
  margin: 10px;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  elevation: 4;
}

.username {
  font-weight: bold;
  color: black;
}

.email {
  margin-bottom: 10px;
  color: black;
}

.energysuppliername {
  margin-bottom: 10px;
  color: black;
}

.userName {
  font-weight: bold;
  color: black;
  margin-bottom: 10px;
}

.userDescription {
  margin-bottom: 10px;
  color: black;
}

.userCategory {
  color: black;
}

.userActions {
  flex-direction: row;
}

.editButtonLabel {
  color: white;
  font-weight: bold;
}

.userCardContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.userCardColumn {
  display: flex;
  flex-direction: column;
}

.detailUserContainer {
  background-color: #eeeeee;
  padding: 20px;
  transition: all 0.3s;
}

.userCardEditAddressUser {
  flex: 1;
  background-color: white;
  border-radius: 4px;
  padding: 20px;
  margin: 10px;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  elevation: 4;
}

.userCardContainerEditAddressUser {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.userCardColumnEditAddressUser {
  display: flex;
  flex-direction: column;
}

.dividerDashedEditAddressUser {
  border-bottom: 2px dashed black;
  border-top: 1px dashed #eeeeee;
  margin: 20px;
}

/* Add media query for screens with width less than or equal to 386px */
@media (max-width: 400px) {
  .userCardContainer {
    flex-direction: column; /* Change the flex direction to stack columns */
    align-items: stretch; /* Stretch the columns to full width */
    display: block !important;
  }

  .userCardColumn {
    margin-bottom: 20px; /* Add some space between columns when stacked */
  }
}
