/* css */
.green-divider {
  width: 80px;
  height: 4px;
  background-color: #8dc63f;
  margin-bottom: 10px;
}

.chartContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.chartSquare {
  width: 100%; /* Occupy full width on smaller devices */
  max-width: 400px; /* Limit maximum width for readability */
  height: 400px; /* Maintain a fixed height */
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 10px;
  position: relative;
  padding-top: 15px;
  margin: 0 auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.squareContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 25px;
  margin: 20px 200px;
}

@media (max-width: 1500px) {
  /* iPad screens */
  .squareContainer {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    margin: 20px 150px;
  }
}

@media (max-width: 750px) {
  /* iPad screens */
  .squareContainer {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin: 20px 50px;
  }
}

@media (max-width: 500px) {
  /* iPad screens */
  .squareContainer {
    grid-template-columns: 1fr;
    gap: 10px;
    margin: 20px 25px;
  }
}

.square {
  justify-self: center;
}

.buttonstyle33A {
  background-color: #ffffff;
  border-radius: 10px;
  color: #8dc63f;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-color: #8dc63f;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-weight: 500;
  max-width: 33%;
  margin-bottom: 20px;
}

.buttonArticle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0;
  margin: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Montserrat, sans-serif;
  line-height: 1.75;
  transition:
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 2px solid #8dc63f;
  width: 100%;
  text-transform: none;
  border-radius: 4px;
  box-shadow: none;
  padding: 0.6rem 1.5rem;
  font-size: 12px;
  font-weight: 600;
  min-width: 84px;
  color: #8dc63f;
}

.beursdata {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Add a media query to adjust the layout for smaller devices */
@media screen and (max-width: 810px) {
  .dashboardContainer {
    flex-direction: column; /* Stack the charts on top of each other */
  }

  .chartContainer {
    margin-bottom: 10px; /* Add some spacing between the stacked charts */
  }
}
