.select-container {
  display: block; /* By default, display as block */
  padding-right: 10px;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  min-width: 100px !important;
}

@media (max-width: 500px) {
  .select-container {
    display: none; /* Hide when screen width is less than 500px */
  }
}
