.containersolar {
  display: flex;
  flex: 1;
}

.parentContainer {
  display: flex;
}

.sidebarContainersolar {
  flex: 1;
  width: 20%;
  background-color: #f9f8fa;
}
.parentContainer {
  width: 100%;
}

.pageContainerSP {
  display: flex;
  background-color: "#f8f9fa";
}

.contentContainersolar {
  display: flex;
  background-color: #f9f8fa;
  padding: 20px;
  box-shadow: none;
  width: 80%;
  height: fit-content;
  transition: all 0.3s;
}

.userCardRequestsolarSP {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  /* margin: 10px; */
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  elevation: 4;
  width: 100%;
}

.userCardRequestColumnssolarSP {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
}

.userCardColumnsolarSP {
  width: 100%;
  padding: 20px;
}

.textColumnsolar {
  width: 20%;
  background-color: #f9f8fa;
  padding: 20px;
}

.formContainersolar {
  margin-top: 20px;
}

.labelsolar {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.inputsolar {
  height: 20px;
  border-color: #ccc;
  border-width: 1px;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 10px;
}

.dropdownsolar {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.optionsolar {
  margin-right: 10px;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
}

.selectedOptionsolar {
  background-color: #ccc;
}

.updateButtonsolar {
  margin-top: 20px;
  background-color: #2196f3;
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.errorMessagesolar {
  color: red;
  margin-bottom: 10px;
}

.dividersolar {
  border-bottom: 1px solid black;
  margin: 20px;
}

.labelsolar {
  display: block;
}

.addressFieldsContainerSP {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

.userCardColumnsolarSP {
  flex: 1;
  width: 50%;
}

.addressFieldsColumnSP {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.addressField {
  width: 100%;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
}

@media (max-width: 812px) {
  .contentContainersolar {
    display: flex;
    background-color: #f9f8fa;
    padding: 20px;
    box-shadow: none;
    width: 80%;
    height: fit-content;
    transition: all 0.3s;
  }
}

@media (max-width: 500px) {
  .userCardRequestColumnssolarSP {
    flex-direction: column;
    gap: 0;
  }

  .userCardColumnsolarSP {
    width: 100%;
    padding: 10px;
  }

  .addressFieldsColumnSP {
    gap: 10px;
  }

  .contentContainersolarSP {
    display: flex;
    background-color: #f9f8fa;
    padding: 20px;
    box-shadow: none;
    width: 80%;
    height: fit-content;
    transition: all 0.3s;
  }

  .parentContainer {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content horizontally */
  }

  .userCardRequestsolarSP {
    width: 100%; /* Full width on smaller screens */
  }

  .textColumnsolar {
    width: 100%; /* Full width on smaller screens */
    margin-top: 20px; /* Add some space between the sections */
  }
}
