.green-divider {
  width: 80px;
  height: 4px;
  background-color: #8dc63f;
  margin-bottom: 10px;
}
.chartContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.chartSquare {
  width: 100%; /* Occupy full width on smaller devices */
  max-width: 400px; /* Limit maximum width for readability */
  height: 400px; /* Maintain a fixed height */
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 10px;
  position: relative;
  padding-top: 15px;
  margin: 0 auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.button-container {
  display: flex; /* Use flex display */
  gap: 10px; /* Add spacing between buttons */
}

/* Optional: If you want to align the buttons to the center horizontally */
.button-container {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.toast {
  display: flex;
  background-color: #8dc63f;
  color: white;
  width: 80%;
  border-radius: 15px;
  overflow: hidden;
  align-items: stretch;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.toast-content {
  display: flex;
  width: 100%;
}

.toast-left {
  width: 33%;
  display: flex;
}

.toast-right {
  padding: 20px;
  margin-top: 25px;
  margin-bottom: 25px;
  width: 67%;
}

.toast-image {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.buttonstylePopUp {
  /* Add styles for the button if necessary */
}

/* Adjust the IconButton styles as needed */

.squareNavStyles {
  flex: 1;
  /* height: 200px; */
  background: linear-gradient(45deg, #8dc63f, #269b66, #ffd700);
  background-size: 300% 300%;
  animation: color 12s ease-in-out infinite;
  background-position: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  /* margin-top: 30px; */
  align-items: flex-start;
  padding-left: 50px;
  position: relative;
  /* padding-top: 15px; */
  padding-right: 50px;
  /* padding-bottom: 40px; */
  padding-bottom: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

/* .logoNav {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(0, -50%);
  z-index: 1;
}

.logoNav img {
  max-height: 300px; 
height: 200px;
  opacity: 0.85; 
  content: url('../../../../public/assets/logo_energyneeds_overlay.png'); 
} */

.squareNavTextStyles {
  font-size: 28px;
  font-weight: bold;
  color: #ffffff;
}

.squareNavParagraphsStyles {
  color: #ffffff;
  font-size: 18px;
}

.buttonWithArrow {
  display: inline-flex;
  align-items: center;
  color: #ffffff; /* Set your desired text color */
  text-decoration: none;
  border: 2px #fff solid;
  padding-bottom: 8px;
  padding-top: 8px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
  background-color: transparent;
  position: relative;
  cursor: pointer;
  font-size: 16px;
}

.buttonWithArrow::after {
  content: "→"; /* Unicode arrow character, you can replace it with an image if needed */
  margin-left: 5px; /* Adjust the spacing between text and arrow */
  font-size: 16px;
}

/* .buttonWithArrow:hover {
  border-bottom: 2px solid #fff; 
} */
.section-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 40px;
}

.section-title {
  font-size: 32px;
  margin-bottom: 10px;
}

.section-paragraph {
  font-size: 18px;
}

.image-column {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
}

/* Hide images beyond the first one on mobile */
/* .hide-on-mobile {
  display: none;
} */
.grid-container {
  display: grid;
  grid-template-rows: auto auto;
  gap: 20px;
}

.grid-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

/* Add a media query to adjust the layout for smaller devices */
@media screen and (max-width: 810px) {
  .dashboardContainer {
    flex-direction: column; /* Stack the charts on top of each other */
  }

  .chartContainer {
    margin-bottom: 10px; /* Add some spacing between the stacked charts */
  }

  .squareContainer {
    justify-content: flex-start; /* Align cards to the left on iPad screens */
  }

  .squareNavStyles {
    flex: 1;
    height: 300px;
    background: linear-gradient(to right, #269b66 30%, #87bd45 100%);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    margin-top: 30px;
    align-items: flex-start;
    padding-left: 50px;
    position: relative;
    padding-top: 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  .toast-content {
    flex-direction: column; /* Stack children in a column on smaller screens */
    align-items: center; /* Center content on smaller screens */
    text-align: center; /* Center text on smaller screens */
  }

  .toast-left img {
    margin-bottom: 15px; /* Add some space between image and text on smaller screens */
  }

  .toast-right {
    padding: 10px; /* Adjust padding on smaller screens */
    margin-top: 15px; /* Adjust margin on smaller screens */
    margin-bottom: 15px; /* Adjust margin on smaller screens */
  }
}

@media (max-width: 480px) {
  .squareContainer {
    flex-direction: column; /* Display cards in a column on smaller screens */
  }

  .squareNavStyles {
    flex: 1;
    height: 350px;
    background: linear-gradient(to right, #269b66 30%, #87bd45 100%);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    margin-top: 30px;
    align-items: flex-start;
    position: relative;
    padding-top: 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
  }
  /* Show only the first image on mobile */
  .hide-on-mobile {
    display: block;
  }
}
