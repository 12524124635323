.centered-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.title {
  font-size: 24px;
  color: #333;
  margin-bottom: 5px;
}

.subtitle {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
  margin-top: 10px;
}

.dividerCenter {
  width: 100px;
  height: 4px;
  background-color: #8dc63f;
  border: none;
}
