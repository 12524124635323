iframe {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

.section-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 40px;
}

.text-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background-color: #f9f8fa;
  padding-left: 10%;
  padding-right: 15%;
}

.text-columntwo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background-color: #f9f8fa;
  padding-left: 10%;
  padding-right: 15%;
}

.text-columnthree {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background-color: #f9f8fa;
  padding-left: 10%;
  padding-right: 15%;
}

.green-divider {
  width: 80px;
  height: 4px;
  background-color: #8dc63f;
  margin-bottom: 10px;
}

.section-title {
  font-size: 32px;
  margin-bottom: 10px;
}

.section-paragraph {
  font-size: 18px;
}

.image-column {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
}

.image-columntwo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
}

.image-columnthree {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
}

.section-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
}

.pageContainerFlu {
  display: flex;
  background-color: "#f8f9fa";
}

.contentContainerFlu {
  /*flex: 1 1;*/
  background-color: white;
  border-radius: 4px;
  padding: 10px;
  margin: 10px;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  elevation: 4;
  height: fit-content;
}

@media (max-width: 768px) {
  .section-container {
    grid-template-columns: 1fr;
  }

  .text-column,
  .image-column {
    padding: 5% 10%;
    text-align: center;
  }

  .green-divider {
    width: 80px;
    margin: 0 auto;
  }

  .image-column {
    order: 2;
  }

  .text-column {
    order: 1;
  }

  .image-columntwo {
    order: 4;
  }

  .text-columntwo {
    order: 3;
  }

  .image-columnthree {
    order: 6;
  }

  .text-columnthree {
    order: 5;
  }

  .section-title {
    font-size: 28px;
    margin-bottom: 10px;
  }
}

/*    
   .section-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 40px;
  }
  
  .text-column,
  .image-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    background-color: #f9f8fa;
    padding-left: 10%;
    padding-right: 15%;
  }
  
  .text-columntwo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    background-color: #f9f8fa;
    padding-left: 10%;
    padding-right: 15%;
  }
  
  .text-columnthree {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    background-color: #f9f8fa;
    padding-left: 10%;
    padding-right: 15%;
  }
  
  .green-divider {
    width: 80px;
    height: 4px;
    background-color: #8dc63f;
    margin-bottom: 10px;
  }
  
  .section-title {
    font-size: 32px;
    margin-bottom: 10px;
  }
  
  .section-paragraph {
    font-size: 18px;
  }
  
  .image-column {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 5%;
  }
  
  .image-columntwo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 5%;
  }
  
  .image-columnthree {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 5%;
  }
  
  .section-image {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
  }
  
  .pageContainerFlu {
    display: flex;
    background-color: #f8f9fa;
  }
  
  .contentContainerFlu {
    background-color: white;
    border-radius: 4px;
    padding: 10px;
    margin: 10px;
    align-items: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    elevation: 4;
    height: fit-content;
  } */

/*   
  @media (max-width: 768px) {
    .section-container {
      grid-template-columns: 1fr; 
    }
  
    .text-column,
    .image-column {
      padding: 5% 10%; 
      text-align: center; 
    }
  
    .green-divider {
      width: 80px;
      margin: 0 auto; 
    }
  
    .image-column {
      order: 2; 
    }
  
    .text-column {
      order: 1; 
    }
  
    .image-columntwo {
      order: 4; 
    }
  
    .text-columntwo {
      order: 3; 
    }
  
    .image-columnthree {
      order: 6; 
    }
  
    .text-columnthree {
      order: 5; 
    }
  }
   */
